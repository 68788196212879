import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Gender } from 'api/participant';
import { ButtonSm } from '../../../../../components/shared/designSystem';
import { useGetRawData } from '../../../../../hooks/useGetRawData';
import { BatteryHeader } from '../../../../report/BatteryHeader';
import { ReportFooter } from '../../../../report/common/ReportFooter';
import ReportHeader from '../../../../report/common/ReportHeader';
import { FormattedSegmentResult } from '../../../common';
import { RawAnswer } from './LongitudinalEpsom.helpers';
import { LongitudinalEpsomLayout } from './LongitudinalEpsom.layout';

type Props = {
	segmentResult: FormattedSegmentResult;
	initialBatteryResultId: string;
	relatedResults: {
		baseline: FormattedSegmentResult;
		longitudinals: FormattedSegmentResult[];
	};
	participantId: string;
	batteryResultId: string;
	headerData: {
		visitType: string;
		externalId: string;
		birthYear: number;
		gender: Gender;
	};
};
type MinimalRawEpsom = { answers: RawAnswer[] };
export const LongitudinalEpsom = (props: Props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: rawLongitudinal } = useGetRawData<MinimalRawEpsom>({
		segmentResultId: props.segmentResult.id,
		fileName: 'longitudinal-epsom.json',
	});
	const { data: rawBaseline } = useGetRawData<MinimalRawEpsom>({
		segmentResultId: props.relatedResults.baseline.id,
		fileName: 'epsom.json',
	});
	const clickHandler = useCallback(() => {
		navigate(
			`/results/${props.participantId}/${props.initialBatteryResultId}`
		);
	}, [props.initialBatteryResultId, props.participantId, navigate]);

	if (!rawLongitudinal || !rawBaseline) return null;
	return (
		<StyledContainer>
			<ReportHeader />
			<StyledReportInfoContainer>
				<BatteryHeader
					batteryTitle={t('web.report.longEpsom.batteryTitle')}
					headerData={props.headerData}
					renderActionButton={() => (
						<ButtonSm
							width='180px'
							onClick={clickHandler}
							text={t('web.report.longEpsom.viewInitialText')}
						/>
					)}
				/>
				<LongitudinalEpsomLayout
					segmentResult={props.segmentResult}
					rawData={{
						baseline: rawBaseline,
						currentLongitudinal: rawLongitudinal,
					}}
					relatedResults={{
						baseline: props.relatedResults.baseline,
						longitudinals: props.relatedResults.longitudinals,
					}}
				/>
			</StyledReportInfoContainer>
			<ReportFooter
				batteryResultsId={props.batteryResultId}
				participantId={props.participantId}
			/>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	() => `
	display: flex;
	align-content: center;
	flex-direction: column;
`
);

const StyledReportInfoContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: column;
	margin-bottom: ${spacing.xl} !important;
	min-width: 834px;
`
);
