import { cloneDeep, omit, isNil } from 'lodash';
import {
	AssessmentResultFragment,
	GetReportForParticipantQuery,
	Maybe,
	SegmentResultFragment,
} from '../../../generated/graphql';
import {
	Assessment,
	Battery,
	BatteryType,
	AssessmentType,
	Segment,
	SegmentType,
	DeepBattery,
	DeepBatteryResult,
} from '@lh/eng-platform-battery-service-rest-client';
import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import { AssessmentStatus } from '../Assessment';

export type Metric = {
	value: number | string;
	version: string;
};
export type MetricHash = {
	[key: string]: Metric;
};

type MetricItem = AssessmentResultFragment['metricItems']['nodes'][0] & {
	__typename?: string;
};
export type BatteryResultGQL =
	GetReportForParticipantQuery['participantV2']['batteryResultById'];

export type FormattedSegmentResult = {
	segment: Partial<Segment>;
	id: string;
	rawDataUrl?: string;
	name: string;
	endTime?: string;
	segmentType: string;
	metricItems: MetricHash;
};

export type FormattedAssessmentResult = {
	assessment: Partial<Assessment>;
	metricItems: MetricHash;
	segmentResults: FormattedSegmentResult[];
};

export type FormattedBatteryResult = {
	id: string;
	battery: Partial<Battery>;
	metricItems: MetricHash;
	assessmentResults: FormattedAssessmentResult[];
};

export const SpecialtyReportSegmentTypes = [
	SegmentType.Clock,
	SegmentType.DigitSymbol,
	SegmentType.RecallDelayed,
	SegmentType.DelayedRecall6,
	SegmentType.RecallImmediate,
	SegmentType.ImmediateRecall6,
	SegmentType.TrailsA,
	SegmentType.TrailsB,
	SegmentType.TrailsV2A,
	SegmentType.TrailsV2B,
	SegmentType.PhonemicFluency,
	SegmentType.SemanticFluency,
	SegmentType.BackwardsDigitSpan,
	SegmentType.DelayedRecognition,
	SegmentType.ComplexPictureDescription,
];

export const QnrIds = { PHQ8: 'phq-8', LHQ15: 'lhq-15' };

const miReduction = (acc: MetricHash, v: MetricItem) => {
	return {
		...acc,
		[v.key]: {
			value: isNaN(Number(v.value))
				? v.value
				: Math.floor(Number(v.value) * 10) / 10, //round to 1 decimal place
			version: v.algorithmVersion,
		},
	};
};

export class BatteryParser {
	private _mutableBattery: FormattedBatteryResult;

	constructor(private readonly _raw_battery: FormattedBatteryResult) {
		this._mutableBattery = cloneDeep(_raw_battery);
	}
	get id() {
		return this._raw_battery.id;
	}
	mutable = () => {
		Object.defineProperties(this, {
			_getSegment: {
				value: this._pullSegment,
			},
			_hasSegment: {
				value: this._stillHasSegment,
			},
		});
		return this;
	};
	hasClock() {
		return this._hasSegment(SegmentType.Clock);
	}

	getClockSegment() {
		if (this.hasClock()) {
			return this._getSegment(SegmentType.Clock);
		}
		return undefined;
	}

	hasHearingScreener() {
		return this._hasSegment(SegmentType.HearingScreener);
	}

	getHearingScreener() {
		if (this.hasHearingScreener()) {
			return this._getSegment(SegmentType.HearingScreener);
		}
	}

	hasLHQ32() {
		return this._hasSegment(SegmentType.Lhq32);
	}

	getLHQ32() {
		if (this.hasLHQ32()) {
			return this._getSegment(SegmentType.Lhq32);
		}
	}

	hasTrails() {
		return (
			this._hasSegment(SegmentType.TrailsA) ||
			this._hasSegment(SegmentType.TrailsB)
		);
	}

	hasTrailsV2() {
		return (
			this._hasSegment(SegmentType.TrailsV2A) ||
			this._hasSegment(SegmentType.TrailsV2B)
		);
	}

	getTrailsSegments() {
		if (this.hasTrails()) {
			return {
				trails_a: this._getSegment(SegmentType.TrailsA),
				trails_b: this._getSegment(SegmentType.TrailsB),
			};
		}
		return undefined;
	}

	getTrailsV2Segments() {
		if (this.hasTrailsV2()) {
			return {
				trails_a: this._getSegment(SegmentType.TrailsV2A),
				trails_b: this._getSegment(SegmentType.TrailsV2B),
			};
		}
		return undefined;
	}

	hasDigitSymbol() {
		return this._hasSegment(SegmentType.DigitSymbol);
	}

	getDigitSymbol() {
		if (this.hasDigitSymbol()) {
			return this._getSegment(SegmentType.DigitSymbol);
		}
		return undefined;
	}

	hasWordRecall() {
		return (
			this._hasSegment(SegmentType.RecallDelayed) &&
			this._hasSegment(SegmentType.RecallImmediate)
		);
	}

	getWordRecall() {
		if (this.hasWordRecall()) {
			// Returning most recent segment since that's what we are pulling the endTime from
			return {
				immediate_recall: this._getSegment(SegmentType.RecallImmediate),
				delayed_recall: this._getSegment(SegmentType.RecallDelayed),
			};
		}
		return undefined;
	}

	isDCRBattery() {
		return [BatteryType.Dcr, BatteryType.DcrPlus].some(
			(b) => b === this._raw_battery.battery.batteryType
		);
	}
	hasDCRAssessment() {
		return [AssessmentType.Dcr, AssessmentType.DcrPlus].some((b) =>
			this._hasAssessment(b)
		);
	}

	getDCRAssessmentData() {
		if (this.hasDCRAssessment()) {
			return this._raw_battery.assessmentResults.find(
				(assessmentResult) =>
					assessmentResult.assessment.assessmentType ===
						AssessmentType.Dcr ||
					assessmentResult.assessment.assessmentType ===
						AssessmentType.DcrPlus
			);
		}
	}

	hasEpsomSegment() {
		return this._hasSegment(SegmentType.Epsom);
	}

	getEpsomSegment() {
		return this._getSegment(SegmentType.Epsom);
	}

	hasLongitudinalEpsomSegment() {
		return this._hasSegment(SegmentType.LongitudinalEpsom);
	}

	getLongitudinalEpsomSegment() {
		return this._getSegment(SegmentType.LongitudinalEpsom);
	}

	// Phonemic Fluency
	hasPhonemicFluency() {
		return this._hasSegment(SegmentType.PhonemicFluency);
	}

	getPhonemicFluency() {
		if (this.hasPhonemicFluency()) {
			return this._getSegment(SegmentType.PhonemicFluency);
		}
		return undefined;
	}

	// Semantic Fluency
	hasSemanticFluency() {
		return this._hasSegment(SegmentType.SemanticFluency);
	}

	getSemanticFluency() {
		if (this.hasSemanticFluency()) {
			return this._getSegment(SegmentType.SemanticFluency);
		}
		return undefined;
	}

	// Backwards Digit Span (BDS)
	hasBackwardsDigitSpan() {
		return this._hasSegment(SegmentType.BackwardsDigitSpan);
	}

	getBackwardsDigitSpan() {
		if (this.hasBackwardsDigitSpan()) {
			return this._getSegment(SegmentType.BackwardsDigitSpan);
		}
		return undefined;
	}

	// Delayed Recognition
	hasDelayedRecognition() {
		return this._hasSegment(SegmentType.DelayedRecognition);
	}

	getDelayedRecognition() {
		if (this.hasDelayedRecognition()) {
			return this._getSegment(SegmentType.DelayedRecognition);
		}
		return undefined;
	}

	// Immediate Recall (6 word)
	hasImmediateRecall6() {
		return this._hasSegment(SegmentType.ImmediateRecall6);
	}

	getImmediateRecall6() {
		if (this.hasImmediateRecall6()) {
			return this._getSegment(SegmentType.ImmediateRecall6);
		}
		return undefined;
	}

	// Delayed Recall (6 word)
	hasDelayedRecall6() {
		return this._hasSegment(SegmentType.DelayedRecall6);
	}

	getDelayedRecall6() {
		if (this.hasDelayedRecall6()) {
			return this._getSegment(SegmentType.DelayedRecall6);
		}
		return undefined;
	}

	// Complex Picture Description
	hasComplexPictureDescription() {
		return this._hasSegment(SegmentType.ComplexPictureDescription);
	}

	getComplexPictureDescription() {
		return this._getSegment(SegmentType.ComplexPictureDescription);
	}

	// Custom QNR Segments
	hasCustomQnrSegment(qnrId: string) {
		return this._hasSegment(
			SegmentType.CustomQuestionnaire,
			(sr) => !isNil(sr) && sr?.segment.metadata?.qnrId === qnrId
		);
	}

	getCustomQnrSegment(qnrId: string) {
		if (this.hasCustomQnrSegment(qnrId)) {
			return this._getSegment(
				SegmentType.CustomQuestionnaire,
				(sr) => !isNil(sr) && sr?.segment.metadata?.qnrId === qnrId
			);
		}
	}

	// Generic
	getGenericParseOfSegment(
		input: SegmentType,
		type: Maybe<string>
	): AssessmentStatus | undefined {
		try {
			// https://linushealth.atlassian.net/browse/PRD-1627 Filter out any segments that should have their own components before
			// falling back to a generic component
			if (SpecialtyReportSegmentTypes.some((srt) => srt === input))
				return;

			const segment = this._getSegment(input);
			if (!segment) return;

			return {
				assessmentName:
					segment?.segment?.name || 'Unknown Segment Name',
				dateTaken: segment?.endTime
					? dateFormatter(segment?.endTime, "MMM d', 'yyyy' at 't")
					: undefined,
				type,
			};
		} catch (e) {
			console.debug(`Error parsing generic segment: `, e);
		}
	}

	private _arFinder(
		type: SegmentType,
		predicate?: (sr?: FormattedSegmentResult) => boolean
	) {
		return (ar: FormattedAssessmentResult) => {
			return ar.segmentResults.some((sr) => {
				let ok = true;
				if (predicate) ok = predicate(sr);
				return ok && sr.segment.segmentType === type;
			});
		};
	}

	private _hasAssessment(type: AssessmentType) {
		return !!this._raw_battery.assessmentResults.some(
			(ar) => ar.assessment.assessmentType === type
		);
	}

	private _hasSegment(
		type: SegmentType,
		predicate?: (sr?: FormattedSegmentResult) => boolean
	) {
		return !!this._raw_battery.assessmentResults.find(
			this._arFinder(type, predicate)
		);
	}

	private _getSegment(
		type: SegmentType,
		predicate?: (sr?: FormattedSegmentResult) => boolean
	) {
		const assessment = this._raw_battery.assessmentResults.find(
			this._arFinder(type, predicate)
		);
		return assessment?.segmentResults.find(
			(sr) => sr.segment.segmentType === type
		);
	}
	private _stillHasSegment(
		type: SegmentType,
		predicate?: (sr?: FormattedSegmentResult) => boolean
	) {
		return !!this._mutableBattery.assessmentResults.find(
			this._arFinder(type, predicate)
		);
	}
	private _pullSegment(
		type: SegmentType,
		predicate?: (sr?: FormattedSegmentResult) => boolean
	) {
		const assessment = this._mutableBattery.assessmentResults.find(
			this._arFinder(type, predicate)
		);
		const segment = assessment?.segmentResults.find((sr) => {
			let ok = true;
			if (predicate) ok = predicate(sr);
			return ok && sr.segment.segmentType === type;
		});
		if (!segment) return;
		this._mutableBattery.assessmentResults =
			this._mutableBattery.assessmentResults.map((ar) => {
				return {
					...ar,
					segmentResults: ar.segmentResults.filter(
						(sr) => sr.id !== segment.id
					),
				};
			});
		return segment;
	}
}

export function mergeBatteryWithResult(
	battery: DeepBattery,
	restBatteryResult: DeepBatteryResult
): FormattedBatteryResult {
	const rbr = formatBatteryResult(restBatteryResult);

	const assessments = battery.assessments;
	const segments = battery.assessments.flatMap((a) => a.segments);
	const brResult: Partial<FormattedBatteryResult> = {
		battery: omit(battery, 'assessments'),
	};
	//Format battery result metrics
	brResult.metricItems = rbr?.metricItems.nodes.reduce(miReduction, {});

	//Format assessment results
	const arFormat = rbr?.assessmentResults.nodes.reduce(
		(arArr: FormattedAssessmentResult[], ar: AssessmentResultFragment) => {
			const assessment = assessments.find(
				(a) => a.id === ar.assessment?.id
			);
			const arResult: Partial<FormattedAssessmentResult> = {
				assessment: omit(assessment, 'segments'),
			};
			//Format assessment result metrics
			arResult.metricItems =
				ar.metricItems.nodes?.reduce(miReduction, {}) || {};

			//Format segment results
			const srFormat = ar.segmentResults.nodes?.reduce(
				(
					srArr: FormattedSegmentResult[],
					sr: SegmentResultFragment
				) => {
					const segment = segments.find(
						(s) => s.id === sr.segment.id
					);
					const srResult: Partial<FormattedSegmentResult> = {
						segment,
					};
					//Format segment result metrics
					srResult.metricItems =
						sr.metricItems.nodes?.reduce(miReduction, {}) || {};
					srArr.push({
						...sr,
						...srResult,
					} as FormattedSegmentResult);
					return srArr;
				},
				[] as FormattedSegmentResult[]
			);

			arResult.segmentResults = srFormat;
			arArr.push({ ...ar, ...arResult } as FormattedAssessmentResult);
			return arArr;
		},
		[] as FormattedAssessmentResult[]
	);
	brResult.assessmentResults = arFormat;
	return { ...brResult } as FormattedBatteryResult;
}

function formatBatteryResult(deepBatteryResult: DeepBatteryResult) {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const res: Record<string, any> = cloneDeep(deepBatteryResult);
	if (res.metricItems) res.metricItems = { nodes: res.metricItems };
	const assessmentResults = deepBatteryResult.assessmentResults.map((ar) => {
		const segmentResults = ar.segmentResults.map((sr) => ({
			...sr,
			metricItems: { nodes: sr.metricItems },
		}));
		return {
			...ar,
			segmentResults: { nodes: segmentResults },
			metricItems: { nodes: ar.metricItems },
		};
	});
	res.assessmentResults = { nodes: assessmentResults };
	return res;
}

export function restBatteryToFormatted(
	br: DeepBatteryResult
): FormattedBatteryResult {
	return {
		...br,
		metricItems: (br.metricItems ?? []).reduce(miReduction, {}),
		assessmentResults: br.assessmentResults.map((ar) => {
			return {
				...ar,
				assessment: { ...ar.assessment },
				metricItems: (ar.metricItems ?? []).reduce(miReduction, {}),
				segmentResults: ar.segmentResults.map((sr) => {
					return {
						...sr,
						name: sr.segment.name,
						segmentType: sr.segment.segmentType,
						segment: { ...sr.segment },
						metricItems: (sr.metricItems ?? []).reduce(
							miReduction,
							{}
						),
					};
				}),
			};
		}),
	};
}
