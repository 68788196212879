enum ReportBatteryTabs {
	DCT_CLOCK = 'DCT Clock',
	WORD_RECALL = 'Word Recall',
	TRAILS = 'Trails',
	DIGIT_SYMBOL = 'Digit Symbol',
	PHONEMIC_FLUENCY = 'Phonemic Fluency',
	SEMANTIC_FLUENCY = 'Semantic Fluency',
	BACKWARDS_DIGIT_SPAN = 'Backwards Digit Span',
	EPSOM_WRITTEN_RESPONSES = 'Written Responses',
	EPSOM_RANKING_RATING = 'Ranking + Rating',
	IMMEDIATE_RECALL_6 = 'Immediate Recall',
	ANIMAL_FLUENCY = 'Category Naming',
	DELAYED_RECALL_6 = 'Delayed Recall',
	DELAYED_RECOGNITION = 'Delayed Recognition',
	COMPLEX_PICTURE_DESCRIPTION = 'Complex Picture Description',
	DCR = 'DCR',
	PHQ8 = 'PHQ-8',
	LHQ15 = 'LHQ-15',
}

export { ReportBatteryTabs };
