/* eslint-disable react-hooks/exhaustive-deps */
import {
	PaginatedOrganization,
	ValueListItem,
} from '@lh/eng-platform-organization-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import {
	getValueListItems,
	useGetOrganization,
	useGetUserOrganizations,
} from 'api/organization';
import { QueryKey } from 'api/query';
import { config } from 'config';
import { ERROR } from 'logging/linusLogger';
import { UserOnlyContext } from '../UserProvider/UserOnlyContext';
import {
	getActiveUserBasedOnRole,
	logoutDueToRole,
} from '../userContextProvider/userSessionHelper';
import { useOrganizationStore } from 'store';

import { OrganizationSessionContext } from './OrganizationContext';

export const OrganizationSessionProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const { user, principal, logout } = useContext(UserOnlyContext);
	const orgStore = useOrganizationStore();
	const queryClient = useQueryClient();

	const { data: _allUserOrgs, error: _allUserOrgsError } =
		useGetUserOrganizations(
			principal?.defaultOrganizationId,
			principal?.id
		);

	useEffect(() => {
		if (principal) {
			const { organizationPermissions } = principal;

			if (!Object.keys(organizationPermissions).length) {
				logout(`${config.researchDomainUrl}/access-denied`);
			}
		}
	}, [principal]);

	useEffect(() => {
		if (!isEmpty(_allUserOrgsError)) {
			ERROR('Error fetching all user orgs for user ', principal?.id);
			return;
		}

		if (isEmpty<PaginatedOrganization[]>(_allUserOrgs)) return;
		orgStore.setAllUserOrganizations(_allUserOrgs);
	}, [_allUserOrgs, _allUserOrgsError]);

	/**
	 * Logout checks.
	 *
	 * First block is for determining if the user has a research org available to continue logging into.
	 *
	 * Second block is for determining if the role in the selected research org is allowed to be logged into.
	 *
	 * */
	useEffect(() => {
		if (!user) return;

		const { organizationId, primaryRole, redirectSymbol, role } =
			getActiveUserBasedOnRole(user);

		if (!organizationId) {
			logout(
				`${config.researchDomainUrl}/access-denied?ot=${redirectSymbol}`
			);
			return;
		}

		const shouldLogout = logoutDueToRole(role?.displayKey ?? '');
		if (isEmpty(role) || shouldLogout) {
			logout(`${config.researchDomainUrl}/access-denied?ot=m`);
			return;
		}

		// Does primaryOrgId belong to the Org or User domain?
		orgStore.setPrimaryOrganizationId(
			primaryRole?.organizationId ?? organizationId
		);
		orgStore.setRole(role);
		orgStore.setCurrentOrganizationId(organizationId);
	}, [user, logout]);

	/**
	 * API call to get the Organization details we're logging into
	 * */

	const {
		data: _deepOrg,
		error: _deepOrgError,
		refetch: _refetchOrg,
	} = useGetOrganization(orgStore.currentOrganizationId);

	useEffect(() => {
		if (_deepOrgError) {
			ERROR('Error fetching current organization ', _deepOrgError);
			return;
		}

		if (isEmpty(_deepOrg)) return;
		orgStore.setCurrentOrganization(_deepOrg);
	}, [_deepOrg, _deepOrgError]);

	/**
	 * API call to get all preference value lists for the org we're apart of
	 * */
	useEffect(() => {
		if (isEmpty(orgStore.currentOrganization)) return;
		// TODO: Maybe explicitly reset stored VLIs here?

		const valueListQueries =
			orgStore.currentOrganization?.preferences.valueLists.map((vl) => {
				return queryClient
					.fetchQuery({
						queryKey: [QueryKey.ValueList, { id: vl.id }],
						queryFn: async () => getValueListItems(vl.id),
					})
					.then((res) => {
						orgStore.setValueListItems(
							vl.type,
							res as ValueListItem[]
						);
					})
					.catch((err) => {
						ERROR('Error fetching value list items', err);
					});
			});

		Promise.allSettled(valueListQueries);
	}, [orgStore.currentOrganization]);

	const setActiveOrg = useCallback(
		async (orgId: string) => {
			orgStore.setCurrentOrganizationId(orgId);
			_refetchOrg();
		},
		[orgStore, _refetchOrg]
	);

	const providedValue = useMemo(
		() => ({
			setActiveOrganizationId: setActiveOrg,
			allUserOrganizations: orgStore.allUserOrganizations,
			currentOrganization: orgStore.currentOrganization,
			currentOrganizationRole: orgStore.role,
			currentOrganizationValueLists: orgStore.valueListItems,
		}),
		[
			setActiveOrg,
			orgStore.allUserOrganizations,
			orgStore.currentOrganization,
			orgStore.role,
			orgStore.valueListItems,
		]
	);

	return (
		<OrganizationSessionContext.Provider value={providedValue}>
			{children}
		</OrganizationSessionContext.Provider>
	);
};
